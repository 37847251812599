import React from "react";
import "./foundation.css";
import { serviceData } from "../const/data";

export const Foundation = React.memo(() => {
  return (
    <main className="mainservice">
      {/* <h1>Foundating Teams</h1>
      <div className="members">
        <section>
          <img src={Imafge} alt="V.Veeramani" className="fnd-img" />
          <h3>Mr. V.Veeramani</h3>
          Founder & Managing Director
        </section>
        <section>
          <img src={Imafge} alt="V.Rajammal" className="fnd-img" />
          <h3>Mrs. V. Rajammal</h3>
          Director - Finance
        </section>
      </div> */}
      {serviceData.map((item, i) => (
        <div className="service-card" key={i}>
          {item.icon}
          <h3>{item.title}</h3>
          <p>{item.content}</p>
        </div>
      ))}
    </main>
  );
});
