import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  token: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUsers: (state, action) => {
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
      };
    },
    removeUser: (state, action) => {
      return { ...state, user: {}, token: "" };
    },
  },
});

export const { setUsers, removeUser } = userSlice.actions;
export default userSlice.reducer;
