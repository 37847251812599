import React from "react";
import { Element } from "react-scroll";
import "./banner.css";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { showOFF } from "../../store/slice/toggleSlice";
import { useDispatch } from "react-redux";

export const Banner = React.memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="background">
      <motion.div
        className="glass-container"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 2, ease: "easeInOut" }}
      >
        <Element className="home" id="home">
          <motion.h1
            className="welcome text-glow-blue"
            initial={{ textShadow: "0 0 5px #00f, 0 0 10px #00f" }}
            animate={{ textShadow: "0 0 20px #00f, 0 0 30px #00f" }}
            transition={{ yoyo: Infinity, duration: 1.5 }}
          >
            WELCOME TO VEERAWEBTECH
          </motion.h1>
          {/* <h1 className="welcome text-glow-blue">WELCOME TO VEERAWEBTECH</h1> */}
          <h5 className="sub-text">Your Success, Our Innovation</h5>
          <p className="main-content">
            Veerawebtech is the leading Web Designing Company In Dharmapuri,
            Tamil Nadu. Initially, we create a awesome website, now we are
            extending our services to build Responsive & mobile-friendly website
            design, eCommerce Website Development, Landing Page Creation,
            WordPress Blog Creation, Website Migration, PSD to HTML Conversion,
            PSD to WordPress Conversion, Logo Design, Social Media Marketing,
            Poster Design, School Software, Gym Software, Billing Software,
            etc.. with the latest technologies.
          </p>
          <button
            className="readmore"
            onClick={() => {
              navigate("/about");
              dispatch(showOFF());
            }}
          >
            Read More
          </button>
        </Element>
      </motion.div>
    </div>
  );
});
