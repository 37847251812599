import React, { useEffect, useState } from "react";
import { Element } from "react-scroll";
import "./product.css";
import Lottie from "lottie-react";
import Webanimation from "../../asset/webanimation.json";
import Mobileanimation from "../../asset/mobileanimation.json";
import { motion, useAnimation } from "framer-motion";
const Product = React.memo(() => {
  const data = [
    {
      icon: Webanimation,
      path: "/folio/web",
      heading: "Web Folio",
      content: "",
    },
    {
      icon: Mobileanimation,
      path: "/folio/mobile",
      heading: "Mobile Folio",
      content: "",
    },
  ];

  const [isVisible, setIsVisible] = useState(false);

  const controls = useAnimation();
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 }
    );

    observer.observe(document.querySelector(".portfolio"));

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (isVisible) {
      controls.start({ opacity: 1, y: 0 });
    }
  }, [isVisible, controls]);

  return (
    // <Element name="portfolio" className="portfolio">
    //   <h3 className="heading" style={{ color: "white" }}>
    //     Portfolio
    //   </h3>
    //   <div className="folio-sec">
    //     {data.map((item, i) => (
    //       <div
    //         style={{ width: "300px", height: "330px", textAlign: "center" }}
    //         key={i}
    //         className="folios"
    //       >
    //         <Lottie animationData={item.icon} loop={true} />
    //         <h3>{item.heading}</h3>
    //       </div>
    //     ))}
    //   </div>
    // </Element>
    <Element name="portfolio" className="portfolio">
      <motion.h3
        className="heading"
        style={{ color: "white", opacity: 0, y: 20 }}
        animate={controls}
      >
        Portfolio
      </motion.h3>
      <div className="folio-sec">
        {data.map((item, i) => (
          <motion.div
            key={i}
            className="folios"
            initial={{ opacity: 0, y: 20 }}
            animate={controls}
            transition={{ delay: (i + 1) * 0.5 }}
            style={{ width: "300px", height: "330px", textAlign: "center" }}
          >
            <Lottie animationData={item.icon} loop={true} />
            <h3>{item.heading}</h3>
          </motion.div>
        ))}
      </div>
    </Element>
  );
});

export default Product;
