import { combineReducers, configureStore } from "@reduxjs/toolkit";
import toggleSlice from "./slice/toggleSlice";
import userSlice from "./slice/userSlice";
import { thunk } from "redux-thunk";

const rootReucer = combineReducers({
  toggle: toggleSlice,
  user: userSlice,
});
export const store = configureStore({
  reducer: rootReucer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});
