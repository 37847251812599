import React, { Suspense } from "react";
import { Home } from "./pages";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { Footer, Header } from "./component";
import { FaWhatsapp } from "react-icons/fa";
import { useSelector } from "react-redux";

const Contact = React.lazy(() => import("./pages/contact/contact"));
const About = React.lazy(() => import("./pages/about/about"));
const Folio = React.lazy(() => import("./pages/folio/folio"));
const Dashboard = React.lazy(() => import("./pages/dashboard/dashboard"));
const User = React.lazy(() => import("./component/user/user"));
const ContactDetails = React.lazy(() =>
  import("./component/contactdetails/contactDetails")
);
const App = () => {
  return (
    <div style={{ position: "relative" }}>
      <Suspense fallback={<div>Loading..</div>}>
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/folio/:type" element={<Folio />} />
            <Route element={<ProtectedRoutes />}>
              <Route path="/dashboard" element={<Dashboard />}>
                <Route path="user" element={<User />} />
                <Route path="contacts" element={<ContactDetails />} />
              </Route>
            </Route>
          </Routes>
          <Footer />
        </Router>
      </Suspense>
      <a href={`https://wa.me/${process.env.REACT_APP_MOBILE}`} target="blank">
        <FaWhatsapp className="whatapp" />
      </a>
    </div>
  );
};

export default App;

const ProtectedRoutes = () => {
  const user = useSelector((store) => store.user.user);
  if (!user) {
    return <Navigate to="/" />;
  }
  return user && <Outlet />;
};
