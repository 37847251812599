import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  show: false,
};

const ToggleSlice = createSlice({
  name: "toggle",
  initialState,
  reducers: {
    showON(state) {
      state.show = true;
    },
    showOFF(state) {
      state.show = false;
    },
  },
});

export const { showON, showOFF } = ToggleSlice.actions;

export default ToggleSlice.reducer;
