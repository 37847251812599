import React, { useEffect, useRef, useState } from "react";
import "./footer.css";
import { motion } from "framer-motion";
import { FaTwitter, FaFacebook, FaLinkedin, FaTelegram } from "react-icons/fa";
import { useLocation } from "react-router-dom";
const Footer = React.memo(() => {
  const [path, setPath] = useState("/");
  const { pathname } = useLocation();
  useEffect(() => {
    setPath(pathname);
  }, [pathname]);
  const [inView, setInView] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setInView(true);
        observer.disconnect();
      }
    });
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, []);
  return (
    <>
      <motion.footer
        className="footer-container"
        ref={ref}
        initial={{ opacity: 0, y: 50 }}
        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: 0.5 }}
      >
        {path !== "/dashboard" && (
          <footer className="footer">
            <div className="sec1">
              <h3 className="copyright">Copyright © 2024 | VeeraWebTech</h3>
              <div className="social-links">
                <a
                  href={`${process.env.REACT_APP_TWITTER}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTwitter />
                </a>
                <a
                  href={`${process.env.REACT_APP_FACEBOOK}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebook />
                </a>
                <a
                  href={`${process.env.REACT_APP_LINKEDIN}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin />
                </a>
                <a
                  href={`${process.env.REACT_APP_TELEGRAM}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTelegram />
                </a>
                {/* <a href={`${process.env.REACT_APP_TWITTER}`} target="_blank" rel="noopener noreferrer">
        <FaWhatsapp />
      </a> */}
              </div>
            </div>
            <address className="address">
              <p>3/18.Nagadhasampatti</p>
              <p> Bikkampatti (post)</p>
              <p>Dharmapuri-636813.</p>
            </address>
          </footer>
        )}
      </motion.footer>
    </>
  );
});
export default Footer;
