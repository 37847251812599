import { Link } from "react-scroll";
import React, { useEffect, useState } from "react";
import "./header.css";
import { data, subheader } from "../const/data";
import logo from "../../asset/wings.png";
import { useLocation, useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoCloseCircle } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { showOFF, showON } from "../../store/slice/toggleSlice";
import { removeUser, setUsers } from "../../store/slice/userSlice";
import { API } from "../api/api";
import { jwtDecode } from "jwt-decode";
const Header = React.memo(() => {
  const navigate = useNavigate();
  const [bgColors, setBgColors] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 30) {
        setBgColors(true);
      } else {
        setBgColors(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [path, setPath] = useState("/");
  // const [login, setLogin] = useState(false);
  const { pathname } = useLocation();
  useEffect(() => {
    setPath(pathname);
  }, [pathname]);

  const show = useSelector((store) => {
    return store.toggle.show;
  });
  const dispatch = useDispatch();
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };
  const handleSubmit = () => {
    API.post("/users/signup", user)
      .then((res) => {
        dispatch(
          setUsers({ user: jwtDecode(res.data.token), token: res.data.token })
        );
        dispatch(showOFF());
        navigate("/dashboard");
      })
      .catch((e) => console.log(e));
  };

  const loggedUser = useSelector((store) => {
    return Object.keys(store.user.user).length > 0;
  });
  return (
    <>
      <>
        <header className={`header ${bgColors ? `darkblue` : `orange`}`}>
          <img
            src={logo}
            alt="veerawebtech"
            className="logo"
            onClick={() => navigate("/")}
          />
          {path === "/" && (
            <div className="mob-sec">
              {show ? (
                <IoCloseCircle
                  className="mobile-icon"
                  onClick={() => dispatch(showOFF())}
                />
              ) : (
                <GiHamburgerMenu
                  className="mobile-icon"
                  onClick={() => dispatch(showON())}
                />
              )}
            </div>
          )}

          {path === "/" && (
            <div
              className={`nav-container ${show ? "mob-nav" : "mob-nav-close"}`}
            >
              <nav>
                {data.map((item, i) => {
                  return (
                    <Link
                      activeClass="active"
                      to={item.path}
                      spy={true}
                      smooth={true}
                      offset={-100}
                      duration={500}
                      key={i}
                      className="headlinks"
                      onClick={
                        item.path === "contact"
                          ? () => {
                              navigate("/contact");
                              dispatch(showOFF());
                            }
                          : () => dispatch(showOFF())
                      }
                    >
                      {item.path.charAt(0).toUpperCase() + item.path.slice(1)}
                    </Link>
                  );
                })}
              </nav>

              {!loggedUser ? (
                <button
                  className="signin"
                  onClick={() => {
                    document.body.style.overflowY = "hidden";
                    dispatch(showON());
                  }}
                >
                  sign in
                </button>
              ) : (
                <button
                  className="signin"
                  onClick={() => {
                    dispatch(removeUser());
                  }}
                >
                  sign out
                </button>
              )}
            </div>
          )}
        </header>
        {path === "/" ? (
          <section className="sub-header">
            {subheader.map((item, i) => {
              return (
                <section
                  className="sub-items"
                  key={i}
                  onClick={
                    item.value === "reach us"
                      ? () => navigate("/contact")
                      : null
                  }
                  style={{ cursor: item.value === "reach us" && "pointer" }}
                >
                  {item.icon}
                  {item.value}
                </section>
              );
            })}
          </section>
        ) : null}

        {show && (
          <div
            className="log-container"
            onClick={() => {
              dispatch(showOFF());
              document.body.style.overflowY = "auto";
            }}
          >
            <div
              className="login-form"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <h3 className="signup"> VeeraWebTech</h3>
              <input
                placeholder="Email"
                name="email"
                value={user.email}
                onChange={handleChange}
              />
              <input
                placeholder="Password"
                name="password"
                type="password"
                value={user.password}
                onChange={handleChange}
              />

              <button className="signin" onClick={handleSubmit}>
                Sign in
              </button>
            </div>
          </div>
        )}
      </>
    </>
  );
});

export default Header;
