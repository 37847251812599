import { Element } from "react-scroll";
import "./service.css";
import React, { useRef, useEffect, useState } from "react";
import { motion } from "framer-motion";
import webdev from "../../asset/webdev.jpg";
import mobdev from "../../asset/mobiledev.svg";
import dmdev from "../../asset/dm.svg";

const Service = React.memo(() => {
  const services = [
    {
      title: "Web Development",
      description:
        "Creating stunning and responsive websites using the latest technologies.",
      image: webdev,
    },
    {
      title: "Mobile Development",
      description:
        "Developing mobile applications for Android and iOS platforms.",
      image: mobdev,
    },
    {
      title: "Digital Marketing",
      description:
        "Providing digital marketing solutions to boost your online presence.",
      image: dmdev,
    },
  ];
  const [inView, setInView] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setInView(true);
        observer.disconnect();
      }
    });
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, []);
  return (
    // <Element name="service" className="service">
    //   <h3 className="heading">Our Service</h3>
    //   <div className="ser-container">
    //     <div className="ser-card">Web Development Services</div>
    //     <div className="ser-card">Ecommerce website design</div>
    //     <div className="ser-card">Mobile Application Development</div>
    //     <div className="ser-card">Digital Marketing</div>
    //     <div className="ser-card">Logo Design</div>
    //   </div>
    // </Element>
    <Element name="service">
      <div className="services-container" ref={ref}>
        {services.map((service, index) => (
          <motion.div
            className="service-card"
            key={index}
            initial={{ opacity: 0, y: 50 }}
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
            transition={{ duration: 0.5, delay: index * 0.3 }}
          >
            <img
              src={service.image}
              alt={service.title}
              className="service-image"
            />
            <h3 className="service-title">{service.title}</h3>
            <p className="service-description">{service.description}</p>
          </motion.div>
        ))}
      </div>
    </Element>
  );
});

export default Service;
